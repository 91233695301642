import {Link} from 'react-router-dom';
import {useState} from "react";

import '../../css/photography.css'
import WindowResize from './WindowResize';

export const MiniPhotoCard = ({collection, header_data, headerImages, index}) => {
    const [ isShown, setShown ] = useState(false);
    const width = WindowResize(); 

    let col_data = header_data[collection];
    var ColLogo = require(`../../icons/collection_logos/${col_data.collection_logo}`).default;
    let path_to = collection.replace(/\_/g, "-");

    let header_url;
    if(headerImages[index].name == col_data.header_image) header_url = headerImages[index].url
    else console.log("failure")

    return (
        <div id = {`c${index + 1}`} key = {index + 1}
            className='collection'>
            <div className='overlay'
                onMouseEnter={() => setShown(true)}
                onMouseLeave={() => setShown(false)}>
                <div className='overlay-image' style={{'backgroundImage' : 'url(' + header_url + ')'}}></div>
                <div 
                className={`overlay-colour ${isShown ? 'hovered' : ''}`}
                    ></div>
            </div>
            <div className='logo-box'
                >
                <div className='centered-logo-box'
                    onMouseEnter={() => setShown(true)}
                    onMouseLeave={() => setShown(false)}>
                    <div className='logo-container'>
                        <Link to = {`/photography/${path_to}`} >
                            <ColLogo className = {col_data.logo_class_name} />
                            {width.isMobile ? (<div/>) : (
                                <p className={`logo-text ${isShown ? 'un-hidden' : ''}`}>
                                    {col_data.under_text}
                                </p>
                            )}
                        </Link>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}