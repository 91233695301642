import React from 'react';
// import { createRoot } from 'react-dom/client'
import {render} from 'react-dom'
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import { Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

import NavigationBar from './components/navigation';
import { GenerateRoutes } from './Routes';

var collections = ['i_heart_brisbane', 'archive'];
var rand_collection = collections[(Math.floor(Math.random() * collections.length))];

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
//     <React.StrictMode>
//         <BrowserRouter>
//             <div className = "homescreen">
//                 <NavigationBar/>
//                 <GenerateRoutes 
//                     collections = {collections}
//                     home_collection = {rand_collection}
//                 />
//             </div>
//         </BrowserRouter>
//     </React.StrictMode>
    
// );

const root = document.getElementById('root');
render(
    <BrowserRouter>
        <div className = "homescreen">
            <NavigationBar/>
            <GenerateRoutes 
                collections = {collections}
                home_collection = {rand_collection}
            />
        </div>
    </BrowserRouter>,
root
)