import React, {useEffect} from 'react'
import "../../css/photo_showcase.css"

var class__name = "slide-img";

const PhotoShowcase = ({images})  => {
    // console.log(images)
    var currentSlide = 1;
    var slideLength = images.length;
    var prevSlide = 0;

    useEffect (() => {
        const updateImages = setInterval(function() {
            if (currentSlide >= slideLength)    currentSlide = 0;
          
            document.getElementById(`img${prevSlide}`).classList.remove("show");
            document.getElementById(`img${currentSlide}`).classList.add("show");
          
            prevSlide = currentSlide;
            currentSlide++;
          
        }, 5000);
        return () => clearInterval(updateImages);
    })
    

    return(
        <div id = 'slider-card-container' className='slider-card-container'>
            {
                images.map((_img, i) => {
                    if (i == 0) class__name = "slide-img show"
                    else    class__name = "slide-img"

                    return(
                        <img 
                        key={_img.key + i} 
                        id = {`img${i}`} 
                        className={class__name}
                        src = {_img.url} />
                    );  
                })
            }
        </div>
    )
        
}

export default PhotoShowcase;