import React, {useState, useLayoutEffect} from 'react';

function WindowResize (){
    let mob = (window.innerWidth < 1000) ? true : false
    const [size, setSize] = useState({
        width: 0,
        isMobile: mob,
    });

    useLayoutEffect(() => {
        function updateSize() {
            if (size.width != 0){
                if (size.isMobile == false && window.innerWidth > 1000) return;
                else if (size.isMobile == true && window.innerWidth < 1000) return;
            }

            let mob = (window.innerWidth < 1000) ? true : false
            setSize({width: window.innerWidth, isMobile: mob});
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, );
    
    return size;
};

export default WindowResize;