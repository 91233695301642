/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'

const SliderContent = props => (
  <div id = 'slider-content'
    css={css`
      transform: translateX(-${props.translate}px);
      transition: transform ease-out ${props.transition}s;
      
      // animation: ${fadeIn} ${props.transition}s;
      // -webkit-animation: ${fadeIn} ${props.transition}s;
      
      height: 100%;
      width: ${props.width}px;
      display: flex;
      content-align: center;`}
  >
    {props.children}
  </div>
)

export default SliderContent