import React from 'react';
import {Link} from 'react-router-dom';

const Page404 = () => {
    return (
        <div>
            <p>
                404 - This page does not exists.<br />
                <Link to='/'>Go back to Home.</Link>
            </p>
        </div>
    );
};

export default Page404;