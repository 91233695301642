import {Route, Routes} from 'react-router-dom';

import AboutMe from './pages/About';
import Home from './pages/Home';
import Photography from './pages/Photography';

// import { Login } from './components/utils/UserAccess';

import Page404 from './pages/Page404';

import Gallery from './components/Gallery';

export const GenerateRoutes = ({collections, home_collection}) => {
    return (
        <Routes>
            <Route path = "/" element = {<Home collection={home_collection}/>}/>
            <Route path = "photography">
                <Route index element = {<Photography collections = {collections} />} />
                {collections.map((collection, index) => {
                    let to_path = collection.replace(/\_/g, "-")
                    return (
                        <Route path = {`${to_path}`}
                            element = {<Gallery collection={collection}/>}
                            key = {collection + index}/>
                    )
                })}
            </Route>
            <Route path = "/about" element = {<AboutMe/>} />
            {/* <Route path = "/sign-in" element = {<Login/>}/> */}
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
}