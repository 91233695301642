import {Link} from 'react-router-dom';
import {useEffect,useState} from "react";
// import { ImageBackground } from 'react-native';

import GetImageArray from '../components/utils/GetImageArray';
import { MiniPhotoCard } from '../components/utils/MiniPhotoCard';

import '../css/photography.css'
// import IHeartBris from '../icons/i3bris.svg'
var header_data = require('../data/header_images.json');

const Photography = ({ collections }) => {
    var images_to_pull = [];
    collections.map((collection) => {images_to_pull.push(header_data[collection].header_image)})
    
    const [isLoading, setLoading] = useState(true);
    const [headerImages, setHeaderImg] = useState([]);
    useEffect (() => {
        header_images()
    }, []);

    const header_images = () => {
        GetImageArray(images_to_pull, 'high_res', 'header_images').then((response) => {
            setHeaderImg(response);
            setLoading(false);
        });
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }
    //relative = "path"
    
    return (
        <div>
            {collections.map((collection, index) => {
                return (
                    <MiniPhotoCard 
                        collection={collection}
                        header_data={header_data}
                        headerImages={headerImages}
                        index={index}
                        key = {index + 1}
                    />
                )
                
            })}
        </div>
        
    );
};

export default Photography;