import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css';

import WindowResize from './utils/WindowResize';
import GetImageArray from '../components/utils/GetImageArray';
import Boop from './utils/Boop';

import LogoSvg from '../icons/navbar/logo.svg'
import ico from '../icons/navbar/favicon.ico'
import CloseIcon from '../icons/close.svg';
import BurgerMenu from '../icons/burger-menu.svg'

function NavigationBar() {
    
    // const [isLoading, setLoading] = useState(true);
    // const [headerImages, setHeaderImg] = useState([]);
    // useEffect (() => {
    //     header_images()
    // }, []);
    const width = WindowResize();

    const [burgerOpen, setBurgerOpen] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (burgerOpen && ref.current && !ref.current.contains(event.target)){
                setBurgerOpen(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        }
    }, [burgerOpen]);

    // const header_images = () => {
    //     GetImageArray(['000006060032_5.jpg', '000019050032_32.jpg'], 'high_res', 'i_heart_brisbane').then((response) => {
    //         setHeaderImg(response);
    //         setLoading(false);
    //     });
    // }

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    
    return(
        <nav ref = {ref} className = "NavigationBar">
            <div className="first-div">
                <Link to="">
                    <LogoSvg className = 'logo'/>
                </Link>
            </div> 
            {width.width < 1000 ?(
                <div className="second-div">
                    
                    <button className='toggle' onClick={() => setBurgerOpen((prev) => !prev)}>
                        <Boop rotation={20} timing={200}>
                            {burgerOpen ? ( <CloseIcon className = 'burger-open'/>): (<BurgerMenu className = 'burger-close'/>)}
                        </Boop> 
                    </button>  
                    
                    <ul className = {`menu-nav${burgerOpen ? ' show-menu' : ''}`}>
                        <li>
                            <div className={`link-container${burgerOpen ? ' show-menu' : ''}`}>
                                <Link to="photography"
                                    onClick={() => setBurgerOpen(false)}>Photography</Link>
                            </div>                            
                        </li>
                        <li>
                            <div className={`link-container${burgerOpen ? ' show-menu' : ''}`}>
                                <Link to="about" onClick={() => setBurgerOpen(false)}>About</Link>
                            </div>
                        </li>
                        <img className = {`img-logo${burgerOpen ? ' show-menu' : ''}`} src = {ico}/>
                    </ul>
                    
                    {/* <ul className = {`menu-nav${burgerOpen ? ' show-menu' : ''}`}>
                        <li>
                            <div className={`underlay${burgerOpen ? ' show-menu' : ''}`}>
                                <div className='underlay-image' style={{'backgroundImage' : 'url(' + headerImages[0].url + ')'}}></div>
                                <div className='underlay-colour'></div>
                                
                            </div>
                            <div className='link-box'>
                                <div className='centered-link-box'>
                                    <div className={`link-container${burgerOpen ? ' show-menu' : ''}`}>
                                        <Link to="photography"
                                            onClick={() => setBurgerOpen(false)}>Photography</Link>
                                    </div>
                                </div>
                            </div>
                            
                        </li>
                        <li>
                            <div className={`underlay${burgerOpen ? ' show-menu' : ''}`}>
                                <div className='underlay-image' style={{'backgroundImage' : 'url(' + headerImages[1].url + ')'}}></div>
                                <div className='underlay-colour'></div>
                                
                            </div>
                            <div className='link-box'>
                                <div className='centered-link-box'>
                                    <div className={`link-container${burgerOpen ? ' show-menu' : ''}`}>
                                        <Link to="about" onClick={() => setBurgerOpen(false)}>About</Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <img className = {`img-logo${burgerOpen ? ' show-menu' : ''}`} src = {ico}/>
                    </ul> */}
                </div>
                ) : (
                    <div className="second-div">
                        <ul className="menu-nav">
                            <li><Link to="photography">Photography</Link></li>
                            {/* <li><Link to="articles">Articles</Link></li> */}
                            <li><Link to="about">About</Link></li>
                        </ul>
                    </div>
                )
                
                
            }
        </nav>
    );      
    
}

export default NavigationBar;