
import React, {useState} from 'react';

import '../../css/index.css'

// import {FaArrowCircleUp} from 'react-icons/fa';
import ArrowUp from '../../icons/arrow-up.svg'

const ScrollToTop = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollUp = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <div className='scroll-button'>
     <ArrowUp onClick={scrollUp} 
    style={{display: visible ? 'inline' : 'none'}} />
    </div>
  );
}
  
export default ScrollToTop;