import React, { useState, useEffect, useRef } from "react";

import GetImageArray from "./utils/GetImageArray";
import ScrollToTop from './utils/ScrollToTop'
import LoadImg from "./utils/LoadImg";
import RandomiseArray from '../components/utils/RandomiseArray';

import '../css/gallery.css';

import LeftArrow from '../icons/left-arrow.svg'
import RightArrow from '../icons/right-arrow.svg'
import CloseIcon from '../icons/close.svg'
// import { MdClose } from 'react-icons/md';

var class_name= 'gallery__item';
var all_images = require('../data/all_images.json'); // in future we wont bring in all here

const Gallery = ({collection}) => {
    var collection2 = collection
    var galleryImages = all_images[collection]    

    var HeaderImage = require(`../icons/collection_logos/${all_images.collection_logos[collection]}`).default;

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const ref = useRef();

    const [isLoading, setLoading] = useState(true);
    const [gallery_images, setImg] = useState({
      low_res: [],
      high_res: [],
    });

    useEffect (() => {
        images()
    }, []);
    
    const images = () => {
      if (isLoading == false) return;
      galleryImages = RandomiseArray(galleryImages)
      if (collection == "archive") collection2 = "out_of_collection"

      var res1 = []
      GetImageArray(galleryImages, 'low_res', collection2, true).then((response1) => {
        setImg({low_res: response1});
        res1 = response1
      }).then((res) =>{
        GetImageArray(galleryImages, 'high_res', collection2, true).then((response2) => {
          setImg({low_res: res1, high_res: response2});
          // console.log(res1)
          // console.log(response2)
          setLoading(false);
        });
      });      
    }

    const handleOpenModal = (index) => {
      setSlideNumber(index)
      setOpenModal(true)     
    }
  
    // Close Modal
    const handleCloseModal = () => {
      setOpenModal(false)
    }
  
    // Previous Image
    const prevSlide = () => {
      slideNumber === 0 
      ? setSlideNumber( galleryImages.length -1 ) 
      : setSlideNumber( slideNumber - 1 )
    }
  
    // Next Image  
    const nextSlide = () => {
      slideNumber + 1 === galleryImages.length 
        ? setSlideNumber(0)
        : setSlideNumber(slideNumber + 1)
    }

    const isVertical = (name) => {
        if (name.includes('vert')){
            return true
        }
        return false
    }  

    useEffect(() => {
        const handler = (event) => {
            if (openModal && ref.current && (ref.current == event.target)){
                setOpenModal(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        }
    }, [openModal]);
    if (isLoading) {
      return <div>Loading...</div>;
    }
    var header_class_name = "header-image";
    console.log(collection)
    if (collection == "i_heart_brisbane") header_class_name = "header-image bris"
    return (
      <div className="gallery-main">
        
        <div className="gallery-header">
          <HeaderImage className={header_class_name}/>
          {/* <img className="header-image" src = {headerImage}/> */}
        </div>
  
        {openModal &&
              <div className='sliderWrap' ref = {ref}>
                <div className='btnPrev' onClick={prevSlide}> <LeftArrow /> </div>
                <div className='btnNext' onClick={nextSlide}> <RightArrow /> </div>
                <div className='fullScreenImage'>
                  <div className='btnClose' onClick={handleCloseModal}> <CloseIcon style={{ width: '2vw', height: '2vw' }} /></div>
                  <LoadImg 
                    url = {gallery_images.high_res[slideNumber].url}
                    className={gallery_images.high_res[slideNumber].dim}
                  />
                </div>
            </div>
        }

        <div className='gallery'>
          {
            gallery_images.low_res && gallery_images.low_res.map((slide, index) => {
              class_name = isVertical(slide.dim) ? 'gallery__item vert' : 'gallery__item horiz'
              
              return (
                  <div
                  className={class_name}
                  key={index - 1}
                  onClick={ () => handleOpenModal(index) }
                  >
                      <LoadImg 
                        url = {slide.url}
                      />
                  </div>
              );
            })
          }
        </div>
        <ScrollToTop/>
      </div>
    )
  }
  
  export default Gallery