import { Storage } from 'aws-amplify';

function getImg (key, name, collection) {
    return new Promise(async (resolve, reject) => {
        const url = await Storage.get(key, {
            level: 'public'
        });
        resolve({
            key,
            name,
            collection,
            url,
        });
    });
}

function getImgWithTags (key, name, dim, tags) {
    return new Promise(async (resolve, reject) => {
        const url = await Storage.get(key);
        resolve({
            key,
            name,
            dim,
            tags,
            url,
        });
    });
}

async function GetImageArray(images, resolution, collection, has_name) {
    // console.log("getting data")
    try {
        if (has_name == true){
            const promises = images.map((img) => getImgWithTags(`${collection}/${resolution}/${img.name}`, img.name, img.dim, img.tags));
            return await Promise.all(promises)
        }else{
            const promises = images.map((img) => getImg(`${collection}/${resolution}/${img}`, img, collection));
            return await Promise.all(promises)
        }
        
    } catch (err) {
        console.error(err);
    }
}

export default GetImageArray;

