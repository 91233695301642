import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LoadImg = ({ url, className}) => {
    try {
        if (className == undefined){
            return <LazyLoadImage src={url}/>;
        }
        return <LazyLoadImage src={url} className={className}/>;
    } catch (error) {
        return null;
    }
};

export default LoadImg;