import React from 'react';
import { useSpring, animated } from 'react-spring';

function useBoop({
    x = 0,
    y = 0,
    rotation = 0,
    scale = 1,
    timing = 150,
    springConfig = {
      tension: 300,
      friction: 10,
    },
  }) {
    const prefersReducedMotion = false; //usePrefersReducedMotion();
    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
      transform: isBooped
        ? `translate(${x}px, ${y}px)
           rotate(${rotation}deg)
           scale(${scale})`
        : `translate(0px, 0px)
           rotate(0deg)
           scale(1)`,
      config: springConfig,
    });
    React.useEffect(() => {
      if (!isBooped) {
        return;
      }
      const timeoutId = window.setTimeout(() => {
        setIsBooped(false);
      }, timing);
      return () => {
        window.clearTimeout(timeoutId);
      };
    }, [isBooped]);
    const trigger = React.useCallback(() => {
      setIsBooped(true);
    }, []);
    let appliedStyle = prefersReducedMotion ? {} : style;
    return [appliedStyle, trigger];
}

const Boop = ({ children, ...boopConfig }) => {
    const [style, trigger] = useBoop(boopConfig);
    return (
        <animated.span onClick={trigger} style={style}>
        {children}
        </animated.span>
    );
};

export default Boop;