import { useEffect, useState } from "react";
import GetImageArray from '../components/utils/GetImageArray';
import WindowResize from '../components/utils/WindowResize';

import "../css/index.css"
import "../css/about.css"

function AboutMe(){
    const width = WindowResize();
    const [isLoading, setLoading] = useState(true);
    const [aboutImages, setHeaderImg] = useState([]);    
    useEffect (() => {
        about_images()
    }, []);

    const about_images = () => {
        GetImageArray(['000019050004_04.jpg', '000002580015_10.jpg', '000019050025_25.jpg'], 
                        'high_res', 
                        'i_heart_brisbane').then((response) => {
            setHeaderImg(response);
            setLoading(false);
        });
    }
    
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return(
        
        <div className='main-div'>
            <div className='about-slide'>
                <h1>About</h1>
                <p>Edward is a Data Scientist based in Brisbane, Australia.
                    He graduated with a Bachelor in Mathematics (Statistics) from the Queensland University of Technology in 2021.</p>
                <p>Currently, he is completing a Masters in Quantitative Finance at the University of Technology, Sydney. 
                    His work encompasses Statistical Modelling, Monte Carlo Methods and Business Process Optimisation.</p>
                <p>Edward also enjoys film photography, which comprises the majority of infermetrica. Shooting primarily on a Canon AE-1 and Yashica-C, 
                    he is inspired by raw city scapes, the delicate beauty of nature and people's candid movment.</p>

                <p>From a young age Edward has held a love for cinema, and eversince he has constantly searched for new films of any genre.
                    In his spare time he loves to write short films and treatments for feature-length screenplays.</p>
            </div>
            <div className = 'images-div'>
                {aboutImages.map((image, index) => {
                    // console.log(image)
                    return (
                        <div 
                            id = {`c${index + 1}`} 
                            key = {index + 1}
                            className='single-image'>
                                <img src={image.url}/>
                        </div>
                    )
                    
                })}
            </div>
            
        </div>
    )
    
}

export default AboutMe;