/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react'
import { flushSync } from 'react-dom'
import { css, jsx } from '@emotion/react'

import SliderContent from './SliderContent'
import Slide from './Slide'
import Arrow from './Arrow'

// window.innerWidth
var getWidth = () => window.innerWidth * 0.8
var getHeight = () => window.innerHeight //* .9
var h = '91vh';
var w = 80;
var t = 0;


/**
 * @function Slider
 */
const Slider = props => {
  const { slides } = props
  
  const firstSlide = slides[0]
  const secondSlide = slides[1]
  const thirdSlide = slides[2]
  const lastSlide = slides[slides.length - 1]

  const [state, setState] = useState({
    activeSlide: 0,
    translate: getWidth(),
    transition: 0.8,
    transitioning: false,
    _slides: [lastSlide, firstSlide, secondSlide, thirdSlide]
  })

  const { activeSlide, translate, _slides, transition, transitioning } = state

  const transitionRef = useRef()
  const resizeRef = useRef()
  const sliderRef = useRef()
  const throttleRef = useRef()

  useEffect(() => {
    transitionRef.current = smoothTransition
    resizeRef.current = handleResize
    throttleRef.current = throttleArrows
  })

  useEffect(() => {
    
    const slider = sliderRef.current
    const smooth = e => {
      if (e.target.id.includes('slider-content')) {
        transitionRef.current()
      }
    }

    const resize = () => {
      resizeRef.current()
    }

    const throttle = e => {
      if (e.target.id.includes('slider-content')) {
        throttleRef.current()
      }
    }

    const transitionStart = slider.addEventListener('transitionstart', throttle)
    const transitionEnd = slider.addEventListener('transitionend', smooth)
    const onResize = window.addEventListener('resize', resize)

    return () => {
      slider.removeEventListener('transitionend', transitionStart)
      slider.removeEventListener('transitionend', transitionEnd)
      window.removeEventListener('resize', onResize)

    }
  }, [])

  useEffect(() => {
    if (transition === 0) setState({ ...state, transition: 0.8, transitioning: false })
  }, [transition])

  const throttleArrows = () => {
    // console.log("throttle")
    setState({...state, transitioning: true })
  } 

  const handleResize = () => {
    setState({ ...state, translate: getWidth(), transition: 0 })
  }

  const nextSlide = () => {
    // console.log("next slide")
    if(transitioning) return
    setState({
      ...state,
      translate: translate + getWidth(),
      activeSlide: activeSlide === slides.length - 1 ? 0 : activeSlide + 1
    })
  }

  const prevSlide = () => {
    if(transitioning) return

    setState({
      ...state,
      translate: 0,
      activeSlide: activeSlide === 0 ? slides.length - 1 : activeSlide - 1
    })
  }

  const smoothTransition = () => {
    let _slides = []
    // We're at the last slide.
    if (activeSlide === slides.length - 1){
      // console.log("1")
      _slides = [thirdSlide, lastSlide, firstSlide, secondSlide]}
    // We're back at the first slide. Just reset to how it was on initial render
    else if (activeSlide === 0) {
      // console.log("2")
      _slides = [lastSlide, firstSlide, secondSlide, thirdSlide]}
    // Create an array of the previous last slide, and the next two slides that follow it.
    else if (activeSlide == slides.length - 2){
      // console.log("3")
      _slides = [slides[activeSlide - 1], slides[activeSlide], lastSlide, firstSlide]}
    else {
      // console.log("else")
      _slides = slides.slice(activeSlide - 1, activeSlide + 3)}
    setState({
      ...state,
      _slides,
      transition: 0,
      translate: getWidth()
    })
  }

  return (
    <div css={SliderCSS} ref={sliderRef}>
      <SliderContent
        translate={translate}
        transition={transition}
        width={getWidth() * _slides.length}
      >
        {_slides.map((_slide, i) => {
          return (
            <Slide width={getWidth()} height = {getHeight()} key={_slide.url + i} content={_slide.url} />
          );
        })}
      </SliderContent>

      <Arrow direction="left" handleClick={prevSlide} />
      <Arrow direction="right" handleClick={nextSlide} />

    </div>
    
  )
}

const SliderCSS = css`
  position: relative;
  height: ${h};
  width: ${w}vw;
  margin: 0 auto;
  top: ${t}vw;
  padding-top: 1.5vh;
  padding-bottom: 1vh;
  overflow: hidden;
  white-space: nowrap;
`

export default Slider