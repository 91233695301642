/** @jsxImportSource @emotion/react */
import React, { memo } from 'react'
import { css, jsx } from '@emotion/react'

const Slide = ({ content, width, height }) => {
  return (
    <div
      css={css`
        max-height: ${height}px;
        width: ${width}px;
        background-image: url('${content}');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-style-right: 0.5px solid white;
        border-style-left: 0.5px solid white;
      `}
    />
  )
}

export default memo(Slide)