/** @jsxImportSource @emotion/react */
import { memo } from 'react'
import { css, jsx } from '@emotion/react'
import LeftArrow from '../../icons/left-arrow.svg'
import RightArrow from '../../icons/right-arrow.svg'

var h = 7.5;
var r = 10;
if (window.innerWidth > 1700) r = 10
else if (1699 < window.innerWidth < 1000) r = 5


const Arrow = ({ direction, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      css={css`
        display: flex;
        position: fixed;
        top: 50%;
        ${direction === 'right' ? `right: ${r}vw` : `left: ${r}vw`};
        height: ${h}vh;
        width: ${h}vh;
        justify-content: center;
        opacity: 0.7;
        border-radius: 75%;
        cursor: pointer;
        align-items: center;
        transition: transform ease-in-out 1s;
        transition: opacity ease-in-out 0.5s;
  
        &:hover {
          transform: scale(1.1);
          opacity: 1;
        }
  
        img {
          transform: translateX(${direction === 'left' ? '-2' : '2'}px);
  
          &:focus {
            outline: 0;
          }
        }
      `}
    >
      {direction === 'right' ? <RightArrow /> : <LeftArrow />}
    </div>
  )
}

export default memo(Arrow)