import React from 'react';
import {useEffect,useState} from "react";

import RandomiseArray from '../components/utils/RandomiseArray';

import "../css/index.css"

import Slider from '../components/slider/Slider';
import PhotoShowcase from '../components/mobile/PhotoShowcase';
import WindowResize from '../components/utils/WindowResize';
import GetImageArray from '../components/utils/GetImageArray';

var home_images = require('../data/home_images.json');

// TODO BUG - ARRAY DOESNT REFRESH IF WINDOW SIZE CHANGES
const Home = ({collection}) => {
    var collection2 = collection
    const width = WindowResize();

    var collection_images = (width.isMobile == true) 
        ? home_images[collection].mobile 
        : home_images[collection].desktop
    collection_images = RandomiseArray(collection_images);

    const [isLoading, setLoading] = useState(true);
    const [image, setImg] = useState([]);    
    useEffect (() => {
        images()
    }, []);
    
    const images = () => {
        if (collection == "archive") collection2 = "out_of_collection"
        GetImageArray(collection_images, 'high_res', collection2, false).then((response) => {
            setImg(response);
            setLoading(false);
        });
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }
    //console.log(image)
    return (
        width.width < 1000 ? <PhotoShowcase images={image}/> : <Slider slides={image} />
    )       
}

export default Home;